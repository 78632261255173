$.fn.fessSearchResultsPlugin = function (options) {
    var pluginName = "fessSearchResultsPlugin",
        appBaseUrl = $("#spsearchtext-fess").attr("data-search-url");

    // Here we need to have a list of Corporate and Railcargo sites. If we add new sites, we need to add them to this list

    var presentationsites = [
        "ÖBB-BCC",
        "ÖBB-Holding",
        "ÖBB-Immobilien",
        "ÖBB-Infrastruktur",
        "ÖBB-Karriere",
        "ÖBB-Konzern",
        "ÖBB-Personenverkehr",
        "ÖBB-Presse",
        "ÖBB-Produktion",
        "ÖBB-Technische Services",
        "ÖBB-Werbung",
        "Rail Cargo Group",
        "Rail Cargo Logistics",
        "Rail Cargo Operator",
        "ROLA",
        "WS-Service",
        "Rail Cargo Hungaria",
        "Rail Equipment",
        "SNNB",
        "Hauptbahnhofcity",
        "Bahnhofcity Wien West",
        "ÖBB-Operative Services",
        "ÖBB-Wohnen",
        "ÖBB Opendata",
        "Rail4Future",
        "ÖBB-Bildungscampus",
    ];

    var labelsites = [
        "corpbccsearch",
        "corpholdingsearch",
        "corpimmosearch",
        "corpinfrasearch",
        "corpkarrieresearch",
        "corpkonzernsearch",
        "corppvsearch",
        "corppressesearch",
        "corpproduktionsearch",
        "corptssearch",
        "corpwerbungsearch",
        "corprcgsearch",
        "corprclsearch",
        "corprcosearch",
        "corprolasearch",
        "corpwsssearch",
        "corprchsearch",
        "corprailequipmentsearch",
        "corpsnnbsearch",
        "corphauptbahnhofcitysearch",
        "corpbahnhofcitywienwestsearch",
        "corpossearch",
        "corpwohnensearch",
        "corpopendatasearch",
        "corprail4futuresearch",
        "corpbildungscampussearch",
    ];

    // default options.
    var settings = $.extend({
        // These are the defaults.
        serviceBaseUrl: appBaseUrl + "/fess-search",
        serviceFullUrl: null,
        lang: "de"
    }, options);

    // Do something to each element here.

    function formatDate(dateInMilliseconds) {
        var date = new Date(dateInMilliseconds);
        var day = date.getDate();
        var dateDay = day < 10 ? "0" + day : day;

        var month = date.getMonth() + 1;
        var dateMonth = month < 10 ? "0" + month : month;

        var dateYear = date.getFullYear();

        return dateDay + "." + dateMonth + "." + dateYear;
    }

    function getSiteLabel(company) {
        switch (company) {
            case "ÖBB-BCC":
                return "bcc";
            case "ÖBB-Holding":
                return "holding";
            case "ÖBB-Immobilien":
                return "immobilien";
            case "ÖBB-Infrastruktur":
                return "infrastruktur";
            case "ÖBB-Karriere":
                return "karriere";
            case "ÖBB-Konzern":
                return "konzern";
            case "ÖBB-Personenverkehr":
                return "personenverkehr";
            case "ÖBB-Presse":
                return "presse";
            case "ÖBB-Produktion":
                return "produktion";
            case "ÖBB-Technische Services":
                return "ts";
            case "ÖBB-Werbung":
                return "werbung";
            case "Rail Equipment":
                return "railequipment";
            case "SNNB":
                return "snnb";
            case "ÖBB-Operative Services":
                return "os";
            case "ÖBB-Wohnen":
                return "wohnen";
            case "ÖBB Opendata":
                return "opendata";
            case "Rail4Future":
                return "rail4future";
            case "Rail Cargo Group":
                return "rcg";
            case "Rail Cargo Logistics":
                return "rcl";
            case "Rail Cargo Operator":
                return "rco";
            case "ROLA":
                return "rola";
            case "WS-Service":
                return "wss";
            case "Rail Cargo Hungaria":
                return "rch";
            case "ÖBB Bildungscampus":
                return "bildungscampus";
            default:
                return "";
        }
    }

    function renderEmptyResultCount(that) {
        var hitsWord = that.attr("data-hits-word");
        var htmlToAppend = "<p>0 " + hitsWord + "</p>";
        that.append(htmlToAppend);
    }

    function renderEmptyResultWord(that) {
        var noResultsWord = that.attr("data-no-results-word");
        var searchKeyword = $("#spsearchtext-fess").attr("data-search-keyword");
        var htmlToAppend = "<div class=\"text-block\"><p>" + noResultsWord + " " + searchKeyword + ".</p></div>";
        that.append(htmlToAppend);
    }

    return this.each(function () {
        var that = $(this);
        var hitsWord = that.attr("data-hits-word");
        var companyWord = that.attr("data-company-word");
        var lastUpdateWord = that.attr("data-last-updated-word");
        var documentTypeWord = that.attr("data-document-type-word");
        var errorOccurredWord = that.attr("data-error-occurred-word");

        var maxResults = $("#spsearchtext-fess").attr("data-max-results");
        var searchCluster = $("#spsearchtext-fess").attr("data-search-cluster");
        var searchKeyword = $("#spsearchtext-fess").attr("data-search-keyword");

        var searchPresentationWebsite = $("#spsearchtext-fess").attr("data-search-presentation-website");
        var searchWebsite = getSiteLabel(searchPresentationWebsite);
        var documentTypeKeyword = $("#spsearchtext-fess").attr("data-search-filetype");
        var timeKeyword = $("#spsearchtext-fess").attr("data-search-time");
        var allWebsitesWord = $("#spsearchtext-fess").attr("data-all-websites-word");
        var allDocumentTypesWord = $("#spsearchtext-fess").attr("data-all-document-types-word");

        var url = settings.serviceBaseUrl + "-" + searchCluster;
        if (settings.serviceFullUrl) url = settings.serviceFullUrl;

        var params = new URLSearchParams(location.search);
        var paramQuery = params.get('q');
        if (paramQuery === null) {
            return;
        }
        if (paramQuery !== null && paramQuery.trim() === '') {
            renderEmptyResultCount(that);
            return;
        }

        $.getJSON(url
            + "?q=" + encodeURIComponent(searchKeyword)
            + "&lang=" + settings.lang
            + "&site=" + searchWebsite
            + "&filetype=" + documentTypeKeyword
            + "&time=" + timeKeyword
            + "&maxResults=" + maxResults,
            function (data)
        {
            var uniqueFilter = function (value, index, that) {
                return that.indexOf(value) === index;
            };

            var searchResults = data.hits;
            var filetypes = $.map(searchResults, function (elem) {
                return elem.filetype.toUpperCase();
            }).filter(uniqueFilter);
            var labels = $.map(searchResults, function (elem) {
                return elem.label;
            }).filter(uniqueFilter).sort();
            var sites = [];

            for (i in labels) {
                sites.push(presentationsites[labelsites.indexOf(labels[i])]);
            }
            sites.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });

            //fills the websites dropdown
            $("#sp-company").append("<option value=\"\" " + (searchPresentationWebsite == "" ? "selected='selected'" : "") + ">" + allWebsitesWord + "</option>");
            for (i in sites) {
                $("#sp-company").parent().parent().removeClass("sp-select-hidden");
                $("#sp-company").append("<option value=\"" + sites[i] + "\" " + (searchPresentationWebsite == sites[i] ? "selected='selected'" : "") + ">" + sites[i] + "</option>");
            }

            // fills the filetypes dropdown
            $("#sp-doctype").append("<option value=\"\" " + (documentTypeKeyword == "" ? "selected='selected'" : "") + ">" + allDocumentTypesWord + "</option>");
            for (i in filetypes) {
                $("#sp-doctype").parent().parent().removeClass("sp-select-hidden");
                $("#sp-doctype").append("<option value=\"" + filetypes[i] + "\" " + (documentTypeKeyword == filetypes[i] ? "selected='selected'" : "") + ">" + filetypes[i] + "</option>");
            }

            // fills the search results
            if (searchResults.length > 0) {
                var htmlToAppend = "<p>" + searchResults.length + " " + hitsWord + "</p>";
                htmlToAppend += "<ul class=\"search-result-list\">";

                for (i in searchResults) {
                    var result = searchResults[i];
                    var url = result.url;

                    htmlToAppend += "<li class=\"search-result-item\">";
                    htmlToAppend += "<div class=\"search-result-item-title\">";
                    htmlToAppend += "<a href=\"" + url + "\">";
                    htmlToAppend += "<span class=\"ic-arrow2-right\" aria-hidden=\"true\"></span>" + result.title + "</a></div>";
                    htmlToAppend += "<div class=\"search-result-item-criterion\">";
                    htmlToAppend += "<span class=\"search-simple-text\">" + companyWord + ": </span>" + presentationsites[labelsites.indexOf(result.label)] + " -";
                    htmlToAppend += "<span class=\"search-simple-text\">" + documentTypeWord + ": </span>" + result.filetype.toUpperCase() + " -";
                    htmlToAppend += "<span class=\"search-simple-text\">" + lastUpdateWord + ": </span>" + formatDate(result.modifiedDate) + "</div>";
                    htmlToAppend += "<div class=\"search-result-item-excerpt\">" + result.content + "</div>";
                    htmlToAppend += "<div class=\"search-result-item-url\">" + url + "</div></li>";
                }

                htmlToAppend += "</ul>";
                that.append(htmlToAppend);
            } else {
                renderEmptyResultCount(that);
                renderEmptyResultWord(that);
                return;
            }
        }).fail(function() {
            var htmlToAppend = "<p>" + errorOccurredWord + "</p>";
            that.append(htmlToAppend);
        });
    });
};


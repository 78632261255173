/**
 * Trigger a callback when 'this' image is loaded:
 * @param {Function} callback
 */
(function ($) {
	$.fn.imgLoad = function (callback) {
		return this.each(function () {
			if (callback) {
				if (this.complete || /*for IE 10-*/ $(this).height() > 0) {
					callback.apply(this);
				}
				else {
					$(this).on('load', function () {
						callback.apply(this);
					});
				}
			}
		});
	};
})(jQuery);

/**
 * Created by z211567 on 26.05.2015.
 */

$.fn.sliderPlugin = function (options) {
	var pluginName = "sliderPlugin";
	// default options.
	var settings = $.extend({
		// These are the defaults.
		color: "#556b2f",
		backgroundColor: "white"
	}, options);
	// Do something to each element here.
	return this.each(function () {
		$(".bc").hide();
		var $slider = $(this);
		var sliderPointText = $slider.attr("data-show-offer-word") + ' ';
		var previusLinkText = $slider.attr("data-show-previous-word");
		var nextLinkText = $slider.attr("data-show-next-word");
		var actionLinkStartText = $slider.attr("data-start-word");
		var actionLinkStopText = $slider.attr("data-stop-word");
		var actionLinkIconStartText = $slider.attr("data-start-and-stop");
		var disableAutoStart = $slider.attr("data-disable-auto-start") ?? false;

		$slider.find("ul").attr("tabindex", -1);
		var pausImagePath = require("../../../images/pause52.svg");
		var pausImagePathWhite = require("../../../images/pause52_white.svg");

		var playImagePath = require("../../../images/play128.svg");
		var playImagePathWhite = require("../../../images/play128_white.svg");

		if ($slider.parents(".slider-container").hasClass("slider-gradient")) {
			pausImagePath = pausImagePathWhite;

			playImagePath = playImagePathWhite;
		}


		if ($slider.data("sliderPlugin") != pluginName) {
			var interval;
			var $sliderControls = $slider.find(".slider__controls").first();
			var initialButtonIcon = disableAutoStart ? playImagePath : pausImagePath; 
			
			var $actionLink = $('<button class="slider-action-link" ><img src="' + initialButtonIcon + '" /></button>');
			var $actionLinkIcon = $actionLink.find("img").first();
			$sliderControls.append($actionLink);
			$actionLink.on('click', function (event) {
				event.stopPropagation()
				event.preventDefault();
				if (interval) {
					stopAnimation();
				} else {
					startAnimation();
				}
			});

			var currentSliderPanel;

			var $sliderPointPanel = $slider.find(".slider-point-panel").first();
			var $sliderPanels = $slider.find("li");

			function setSliderPanelHeight(slidePanel) {
				var windowWidth = $(window).width();
				if (!slidePanel.closest(".slider-full-width").length) {
					windowWidth = Math.min(windowWidth, 1200);
				}
				$slider.width(windowWidth);
				$slider.css('left', "50%");
				$slider.css('margin-left', -windowWidth / 2);
				slidePanel.find("ul").first().width(windowWidth);
				slidePanel.width(windowWidth);
				slidePanel.css("position", "relative");
				var sliderHeight = slidePanel.find("img").first().height();
				if (settings.fullscreen) {
					sliderHeight = $(window).height() - $('.intro-header').height();
				}
				if (slidePanel.closest(".slider-full-width").length) {
					sliderHeight = Math.min(sliderHeight, 480);
				}
				slidePanel.height(sliderHeight);
				slidePanel.find("ul").first().height(sliderHeight);
				$slider.height(sliderHeight);
				slidePanel.css("position", "absolute");
				slidePanel.find(".slider-link").first().width(windowWidth);
				slidePanel.find(".slider-link").first().height(sliderHeight);
			}					

			var $sliderView = $slider.find(".slider__view").first();

			var contentPanel = $('<div class="slider-content-link-panel"></div>');
			contentPanel.append('<button class="slider-content-link slider-content-link-prev" aria-label="' +
				previusLinkText + '">' +
				'<span class="ic-arrow4-left" aria-hidden="true"></span>' +
				'</button>' +
				'<button class="slider-content-link  slider-content-link-next" aria-label="' +
				nextLinkText + '">' +
				'<span class="ic-arrow4-right"  aria-hidden="true"></span>' +
				'</button>');

			$sliderControls.append(contentPanel);
			$sliderPanels.each(function (index) {
				$(this).attr("id", "panel" + (index + 1));
				if (settings.fullscreen) {
					var windowWidth = $(window).width();
				} else {
					var windowWidth = Math.min($(window).width(), 1200);
				}

				var $sliderPoint = $('<button class="slider-point' + (index == 0 ? ' active' : '') + '"' +
					'aria-controls="panel' + (index + 1) + '"' + 'role="tab"' + 'aria-selected="' + (index == 0 ? 'true' : 'false') + '">' +
					'<span class="screen-reader-text">' + sliderPointText + (index + 1) + '</span></button>');

				$sliderPoint.on('click', function (event) {
					event.preventDefault();
					stopAnimation();
					show(index);
				});
				$(this).width(windowWidth);

				$(this).css("position", "absolute");
				var imageLoader = $("<div class=\"loader loader-l\" style='opacity:1;'></div>");
				$(this).append(imageLoader);


				var firstSliderImage = $(this).find(".slider-img").first();
				if (firstSliderImage) {
					firstSliderImage.css("opacity", 0);
					firstSliderImage.imgLoad(function () {
						imageLoader.css("opacity", 0);
						firstSliderImage.css("opacity", 1.0);
					})
				}

				$(this).find(".slider-link").first().width(windowWidth);
				$(this).find(".slider-link").first().height($(this).height());

				$sliderPointPanel.append($sliderPoint);

				$(this).find("a").first().on('movestart', function (e) {
					// If the movestart is heading off in an upwards or downwards
					// direction, prevent it so that the browser scrolls normally.
					if ((e.distX > e.distY && e.distX < -e.distY) ||
						(e.distX < e.distY && e.distX > -e.distY)) {
						e.preventDefault();
					}
				});
				$(this).find("a").first().on('swiperight', function (event) {
					event.stopPropagation()
					event.preventDefault();
					stopAnimation();
					if (currentIndex == 0) {
						show($sliderPanels.length - 1, 'right');
					} else {
						show(currentIndex - 1);
					}

				});
				$(this).find("a").first().on('swipeleft', function (event) {
					event.stopPropagation()
					event.preventDefault();
					stopAnimation();
					if (currentIndex + 1 == $sliderPanels.length) {

						show(0, 'left');
					} else {
						show(currentIndex + 1);
					}
				});
			});

			var $nextLink = $('.slider-content-link-next');

			$nextLink.on('click', function (event) {
				event.stopPropagation()
				event.preventDefault();
				stopAnimation();
				if (currentIndex + 1 == $sliderPanels.length) {

					show(0, 'left');
				} else {
					show(currentIndex + 1);
				}
			});
			var $prevLink = $('.slider-content-link-prev');

			$prevLink.on('click', function (event) {
				event.stopPropagation()
				event.preventDefault();
				stopAnimation();
				if (currentIndex == 0) {
					show($sliderPanels.length - 1, 'right');
				} else {
					show(currentIndex - 1);
				}
			});

			var $sliderPoints = $sliderPointPanel.find(".slider-point");
			var sliderPanel = $($sliderPanels[0]);
			setSliderPanelHeight(sliderPanel);

			var currentIndex = 0;

			var startAnimation = function () {
				if (sliderPanel) {
					sliderPanel.addClass("slider-progress-transition");
					sliderPanel.addClass("active");
				}
				if (!interval) {
					$actionLinkIcon.attr("src", pausImagePath);
					$actionLinkIcon.attr("alt", actionLinkStopText);
					$actionLink.attr("aria-pressed", true);
					$actionLink.attr("aria-label", actionLinkIconStartText);
					interval = setInterval(function () {

						if (currentIndex == $sliderPanels.length - 1) {
							show(0, 'left');
						} else {
							show(currentIndex + 1);
						}
					}, 5050);
				}
			}

			$(".slider-img-wrapper").each(function (i, elm) {
				var $img = $(elm).find('img').first();
				$(elm).css('background-image', 'url(' + $img.attr("src") + ')');
				$img.css("opacity", "0");
			});

			var firstSliderImage = sliderPanel.find(".slider-img").first();
			if (firstSliderImage) {
				if (disableAutoStart) {
					firstSliderImage.imgLoad(stopAnimation);
				} else {
					firstSliderImage.imgLoad(startAnimation)
				}
			}


			var stopAnimation = function () {
				clearInterval(interval);
				interval = null;
				if (sliderPanel) {
					sliderPanel.removeClass("slider-progress-transition");

				}
				if (currentSliderPanel) {
					currentSliderPanel.removeClass("slider-progress-transition");
				}
				$actionLinkIcon.attr("src", playImagePath);
				$actionLinkIcon.attr("alt", actionLinkStartText);
				$actionLink.attr("aria-pressed", false);
				$actionLink.attr("aria-label", actionLinkIconStartText);
			}

			var show = function (index, direction) {
				var oldPosition = "-100%";
				var newPosition = "100%";

				if (currentIndex != index) {
					if (!direction) {
						if (index < currentIndex) {

						} else {
							oldPosition = "100%";
							newPosition = "-100%";
						}
					} else if (direction == 'left') {
						oldPosition = "100%";
						newPosition = "-100%";
					} else if (direction == 'left') {
						oldPosition = "-100%";
						newPosition = "100%";
					}

					currentIndex = index;

					if (sliderPanel) {
						currentSliderPanel = sliderPanel;
					}

					sliderPanel = $($sliderPanels[index]);
					sliderPanel.css('left', oldPosition);
					sliderPanel.show();
					setSliderPanelHeight(sliderPanel);
					currentSliderPanel.css('z-index', 2);
					currentSliderPanel.addClass("transition");
					$slider.addClass("transition")

					currentSliderPanel.one("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
						currentSliderPanel.removeClass("transition");
						currentSliderPanel.css('z-index', 1);
						$slider.removeClass("transition")
						$sliderPoints.removeClass("active");
						$sliderPoints.attr('aria-selected', false);
						currentSliderPanel.hide();
						$($sliderPoints[index]).addClass("active");
						$($sliderPoints[index]).attr('aria-selected', true);
					});
					currentSliderPanel.show();
					currentSliderPanel.css('left', newPosition);


					sliderPanel.addClass("transition");

					sliderPanel.one("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {

						sliderPanel.removeClass("transition");
						$('.slider-progress-transition').removeClass("slider-progress-transition");
						$('.slider-progress-transition').removeClass("active");
						if (interval) {
							sliderPanel.addClass("slider-progress-transition");
							sliderPanel.addClass("active");
						}


					});
					sliderPanel.show();
					sliderPanel.css('z-index', 3);
					sliderPanel.css('left', '0%');

				}
			}
			$(window).on('resize', function () {

				if (currentSliderPanel) {
					setSliderPanelHeight(currentSliderPanel);
				}

				if (sliderPanel) {
					setSliderPanelHeight(sliderPanel);
				}
			});
			$slider.data("pluginName", pluginName);
		}
	});


};


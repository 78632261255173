$.fn.serviceNavPlugin = function (options) {
	var pluginName = "serviceNavPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.


	return this.each(function () {
		var $nav = $(this);

		if (!$nav.data(pluginName)) {
			var allListItems = new Array();
			var currentOpenListIem;
			var currentMenuPanel;


			$.each($nav.children("li"), function (index, listItem) {
				var $listItem = $(listItem);
				allListItems.push($listItem);

				// Überprüfe ob ein Dropdown-Menüvorhanden ist.
				if ($listItem.children("div").length > 0) {
					// Dropdown-Menü.
					var submenuPanel = $listItem.children("div").first();

					if (submenuPanel.hasClass("full")) {
						// Because of defered scripts we need to wait until the page is rendered, 100 ms seems to be good
						$listItem.hide();
						setTimeout(function () {
							$listItem.show();
							submenuPanel.css("width", $(window).width());
							submenuPanel.css("left", $listItem.offset().left * -1);
						}, 100);
					}

					var $handler = $(".js-service-nav-handler", $listItem);
					$handler.on('click',function (event) {
						event.preventDefault()
						event.stopPropagation();
						if (currentOpenListIem && currentOpenListIem == $listItem) {
							currentOpenListIem.removeClass("open");
							currentOpenListIem = null;
							if (currentMenuPanel) {
								currentMenuPanel.hide();
								currentMenuPanel = null;
							}
							return;
						}
						submenuPanel.slideDown("fast", function () {
								$listItem.addClass("open");
								currentOpenListIem = $listItem;
								currentMenuPanel = submenuPanel;
								$("#searchtext-fess").trigger('focus');
							}
						);
					})

					var $focusableElements = submenuPanel.find("input, button, [tabindex]:not([tabindex='-1']), a, select, textarea");
					var $firstElementHandler = $focusableElements.first();
					var $lastElementHandler = $focusableElements.last();

					$lastElementHandler.on('keydown',function (event) {
						if(event.keyCode === 9 && !event.shiftKey) {
							closeMenu();
						}
					});

					$firstElementHandler.on("keydown", function (event) {
						if (event.keyCode === 9 && event.shiftKey) {
							closeMenu();
						}
					});
				}
			});

			function closeMenu() {
				if (currentOpenListIem) {
					currentOpenListIem.removeClass("open");
					currentOpenListIem = null;
				}
				if (currentMenuPanel) {
					currentMenuPanel.hide();
					currentMenuPanel = null;
				}
				$.each(allListItems, function (index, listItem) {
					listItem.removeClass("open");
					var menuPanel = listItem.children("ul").first();
					if (menuPanel) {
						menuPanel.hide();
					}
				});
			}

			$("*").on('click', function (e) {
				var $target = $(e.target);

				if ($target[0].id == 'third-skiplink') return false;

				if (document.getElementById("header-search-box")) {
					if (!$.contains(document.getElementById("header-search-box"), $target[0]) && (currentOpenListIem && !$.contains(currentOpenListIem[0], $target[0]))) {
						closeMenu();
					}
				}
			});

			/*
			 window.addEventListener('scroll', function (event) {
			 closeMenu()
			 }, false);
			 */

			$(window).on('resize', function () {
				var submenuPanel = $("#header-search-box");
				var listItem = $(".service-nav > .service-nav__list-item-search-icon");
				if (submenuPanel.hasClass("full")) {
					submenuPanel.css("width", $(window).width());
					submenuPanel.css("left", listItem.offset().left * -1);
				}
			});

			$nav.data(pluginName, true);
		}
	});
};